import _ from 'lodash'
import Modernizr from 'modernizr'
import Swiper from 'swiper'
import { TweenMax, TimelineMax, Power1, Power4, CSSPlugin } from 'gsap/TweenMax'
import View from 'js/Component/View';
import Text from 'js/Component/Text'
import Animation from 'js/Component/Animation';

class PrivacypolicyView extends View {
    constructor() {
        super();
    }

    init() {
        super.init();

        this.animation = new Animation();

        this.render();
    }

    beforeRender() {
        super.beforeRender();

        this.setView();
        this.setObserve();
    }

    render() {
        super.render();
    }

    afterRender() {
        if ( Modernizr.touchevents ) {
            return false;
        }
    }

    setView() {
        if ( Modernizr.touchevents ) {
            return false;
        }
    }

    setObserve() {
        if ( Modernizr.touchevents ) {
            return false;
        }

        const self = this;

        _.forEach(this.observe.element, (element, index) => {
            if ( $(element.watchItem).hasClass('js-heading') ) {
                this.animation.setHeading('.js-heading');
                element.fullyEnterViewport(function() {
                    self.animation.enterHeadingInView(this.watchItem);
                });
            }

            if ( $(element.watchItem).hasClass('js-anim-dash') ) {
                this.animation.setDash(element.watchItem);
                element.fullyEnterViewport(function() {
                    self.animation.renderDash(this.watchItem);
                });
            }

            if ( $(element.watchItem).hasClass('js-anim-typing') ) {
                element.fullyEnterViewport(function() {

                    self.animation.typeWrite(this.watchItem);
                });
            }

            if ( $(element.watchItem).hasClass('js-anim-btn') ) {
                this.animation.setBtn(element.watchItem);
                element.fullyEnterViewport(function() {
                    self.animation.renderBtn(this.watchItem);
                });
            }

            if ( $(element.watchItem).hasClass('js-anim-slidefromleft') ) {
                this.animation.setSlide(element.watchItem, -40);
                element.fullyEnterViewport(this.animation.renderSlide);
            }

            if ( $(element.watchItem).hasClass('js-anim-tags') ) {
                this.animation.setFilter(element.watchItem, true);
                element.fullyEnterViewport(this.animation.renderChildFilter);
            }

            if ( $(element.watchItem).hasClass('js-anim-filter') ) {
                this.animation.setFilter(element.watchItem, false, true);
                element.fullyEnterViewport(this.animation.renderFilter);
            }

        });
    }
}

export default PrivacypolicyView;