var cache = {};

function importAll (r) {
    r.keys().forEach(key => cache[key] = r(key));
}

importAll(require.context('../images/', true, /\.(png|jpe?g|svg)$/));
importAll(require.context('material-design-icons/iconfont/', true, /\.(eot|ttf|woff2?)$/));

import '../scss/privacypolicy.scss'
import View from 'js/View/PrivacypolicyView'

const view = new View();
view.init();
